import React from 'react';

import { cleanValue, getObjectValue, getUserLocalInfo, innerHTMLToText, is, numberWithCommas, parse, textToInnerHTML } from '@onesy/utils';
import { Type } from '@onesy/ui-react';
import { colors } from '@onesy/style-react';
import { OnesyDate, format } from '@onesy/date';
import { IResponse } from '@onesy/sdk/other';
import { IContact } from '@onesy/api';

import { ErrorBoundary } from 'ui';
import { ISignedIn } from './types';

export const FONT_FAMILY = {
  primary: ['Montserrat', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'sans-serif'].join(', '),
  secondary: ['Outfit', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(', '),
  tertiary: ['Roboto Mono', 'monospace'].join(', ')
};

export const lazy = (Element: any) => (props: any) => (
  <React.Suspense fallback={<></>}>
    <Element {...props} />
  </React.Suspense>
);

export const RouteWrapper = React.forwardRef((props: any, ref: any) => {
  const {
    children,

    ...other
  } = props;

  return (
    <ErrorBoundary
      ref={ref}

      {...other}
    >
      {children}
    </ErrorBoundary>
  );
});

export const wrapper = (Element: any) => (
  <RouteWrapper
    fallback
  >
    <Element />
  </RouteWrapper>
);

export const googleLibrary: any = {
  oauth2: '',
  callback: ''
};

export const getErrorMessage = (result: IResponse) => result?.response?.meta?.message || 'Error occured';

export const formats = {
  entire: 'DD MMM, YYYY [at] h:mm a',
  date: 'DD MMM, YYYY',
  time: 'h:mm a'
};

export const getDate = (value: number = OnesyDate.milliseconds, version: 'entire' | 'date' | 'time' = 'date') => format(new OnesyDate(value), (formats as any)[version]);

export const PAGINATION_LIMIT = 14;

export const LOAD_MORE_LIMIT = 300;

export const getSettings = (signedIn: ISignedIn, value: string) => {
  const result = getObjectValue(signedIn?.organization?.personalization, value);

  return result !== undefined ? result : getObjectValue(signedIn?.user, value);
};

export const NOTIFICATION_VERSIONS = {
  noteMention: 'note-mention',
  taskAssign: 'task-assign'
};

export const addStyle = (value: string, id?: string) => {
  const styleElement = window.document.querySelector(`#${id}`);

  if (styleElement) styleElement.remove();

  const head = window.document.head;

  const style = window.document.createElement('style');

  style.innerHTML = value;

  head.appendChild(style);
};

export const getDeviceAndLocation: any = async () => {
  const device = parse(window.localStorage.getItem('onesy_device-id'));

  const location = await getUserLocalInfo();

  return {
    device,
    location
  };
};

export const menuItemProps = (value: any) => ({
  button: true,

  size: 'small',

  menuCloseOnClick: true,

  ...value,

  primary: (
    <Type
      version='b2'
    >
      {value.primary}
    </Type>
  )
});

export const getParamID = (split = '/', location_ = window.location): any => {
  const id = location_.pathname.split(split).filter(Boolean).slice(-1)[0]?.replaceAll('/', '');

  if (id?.length >= 24 && !['add'].includes(id)) return id;

  return null;
};

export const getElementText = (value: any): string => {
  const element = window.document.createElement('div');

  element.innerHTML = textToInnerHTML(value);

  return (element.innerText || element.textContent) as string;
};

export const importVCards = (value_: string) => {
  const values: any = [];

  let value = value_
    .split('END:VCARD')
    .map((item: any) => item.split(/[\n\r]/).filter((item: any) => !!item && !['BEGIN:VCARD', 'END:VCARD'].includes(item)))
    .filter((item: any) => !!item.length);

  value.forEach(vCard => {
    const object: any = {};

    const properties: any = [];

    vCard.forEach((item: any) => {
      let itemValue = item.trim();

      // Name 
      if (itemValue.startsWith('FN')) object.name = itemValue.replace('FN:', '');
      // Nickname 
      else if (itemValue.startsWith('NICKNAME')) object.nickname = itemValue.replace('NICKNAME:', '');
      // Description 
      else if (itemValue.startsWith('NOTE')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        if (!object.description) {
          itemValue = innerHTMLToText(itemValue);

          object.description = itemValue;
        }
        else {
          properties.push({
            version: 'custom',
            value: itemValue
          });
        }
      }
      // Email 
      else if (itemValue.startsWith('EMAIL')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'email',
          value: itemValue
        });
      }
      // Mobile, tel, fax 
      else if (itemValue.startsWith('TEL')) {
        const [meta, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: !meta.toLowerCase().includes('fax') ? 'mobile' : 'fax',
          value: itemValue
        });
      }
      // URL 
      else if (itemValue.startsWith('URL')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'url',
          value: itemValue
        });
      }
      // Address 
      else if (itemValue.startsWith('ADR')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':').replace(/;/g, ' ').trim();

        properties.push({
          version: 'address',
          value: itemValue
        });
      }
      // Title 
      else if (itemValue.startsWith('TITLE')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'title',
          value: itemValue
        });
      }
      // Company
      else if (itemValue.startsWith('ORG')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'company',
          value: itemValue
        });
      }
      // Birthday
      else if (itemValue.startsWith('BDAY')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'birthday',
          value: new OnesyDate(new Date(`${itemValue.slice(4, 6)}-${itemValue.slice(6, 8)}-${itemValue.slice(0, 4)}`)).milliseconds
        });
      }
      // Date 
      else if (itemValue.startsWith('REV')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'date',
          value: new OnesyDate(new Date(itemValue)).milliseconds
        });
      }
    });

    const objectProperties: any = {};

    properties.forEach((item: any) => {
      let property: any;
      const version = item.version;

      if (['mobile', 'tel', 'fax'].includes(version)) property = 'tels';
      else if (['email'].includes(version)) property = 'emails';
      else if (['title'].includes(version)) property = 'titles';
      else if (['url'].includes(version)) property = 'urls';
      else if (['address'].includes(version)) property = 'addresses';
      else if (['company'].includes(version)) property = 'companies';
      else if (['birthday'].includes(version)) property = 'birthdays';
      else if (['date'].includes(version)) property = 'dates';
      else if (['custom'].includes(version)) property = 'custom';

      if (!objectProperties[property]) objectProperties[property] = [];

      objectProperties[property].push(item);
    });

    object.properties = objectProperties;

    values.push(object);
  });

  return values;
};

export const exportVCard = (items: IContact[]) => {
  const values: any = [];

  items.forEach(contact => {
    let value = `BEGIN:VCARD
VERSION:3.0\n`;

    // Full name 
    const name = textToInnerHTML(contact.name);

    if (name) {
      value += `FN:${name}\n`;

      const nameParts = name.split(' ').slice(0, 4);

      value += `N:${nameParts.join(';')}${';'.repeat(4 - nameParts.length)}\n`;
    }

    // Nickname 
    if (contact.nickname) value += `NICKNAME:${contact.nickname}\n`;

    const description = contact.description ? getElementText(contact.description) : '';

    if (description) value += `NOTE:${description}\n`;

    Object.keys(contact.properties || {}).forEach(key => {
      const values: any = contact.properties?.[key] || [];

      values.forEach((item: any) => {
        const version = item.version!;

        // Email 
        if (version === 'email') value += `EMAIL;TYPE=INTERNET:${item.value}\n`;

        // Mobile, tel, fax 
        if (['mobile', 'tel', 'fax'].includes(version)) value += `TEL;TYPE=${['mobile', 'tel'].includes(version) ? 'CELL' : 'FAX'}:${item.value}\n`;

        // URL 
        if (version === 'url') value += `URL;TYPE=WORK:${item.value}\n`;

        // Address 
        if (version === 'address') value += `ADR;TYPE=home:;;${item.value};;;;\n`;

        // Title 
        if ((version as any) === 'title') value += `TITLE:${item.value}\n`;

        // Company
        if (version === 'company') value += `ORG:${item.value}\n`;

        // Birthday
        if (version === 'birthday') value += `BDAY:${format(new OnesyDate(item.value), `YYYYMMDD`)}\n`;

        // Date 
        if (version === 'date') value += `REV:${new OnesyDate(item.value).iso}\n`;

        // Custom 
        if (version === 'custom') value += `NOTE:${item.value}\n`;
      });
    });

    // End 
    value += `CATEGORIES:myContacts
END:VCARD`;

    values.push(value);
  });

  return values.join('\n');
};

export const getRootPage = (signedIn: any) => {
  const features = signedIn?.user.is;

  if (getSettings(signedIn, 'settings.main') === 'task' && features?.app_task) return '/tasks';

  if (getSettings(signedIn, 'settings.main') === 'note' && features?.app_note) return '/notes';

  if (getSettings(signedIn, 'settings.main') === 'urlShortener' && features?.app_urlShortener) return '/url-shorteners';

  if (getSettings(signedIn, 'settings.main') === 'website' && features?.app_website) return '/websites';

  if (getSettings(signedIn, 'settings.main') === 'chat' && features?.app_chat) return '/chats';

  if (getSettings(signedIn, 'settings.main') === 'contact' && features?.app_contact) return '/contacts';

  if (getSettings(signedIn, 'settings.main') === 'location' && features?.app_location) return '/locations';

  return '/';
};

export const colorOptions: any = {};

Object.keys(colors).forEach((item: any) => {
  const color = (colors[item as 'green'])['A200'] || (colors[item as 'green'])['400'] || colors[item as 'white'];

  colorOptions[item] = {
    name: cleanValue(item, { capitalize: true }),
    color,
    value: item
  };
});

export const dates = ['added_at', 'updated_at', 'received_at', 'removed_at', 'resolved_at', 'starts_at', 'ends_at', 'created'];

export const booleans = ['active', 'archived', 'pinned', 'private', 'default', 'main', 'onesy', 'google', 'resolved'];

export const references = ['contacts', 'contact_groups', 'users'];

const itemToURLMap: any = {
  media: '/library',
  task: '/tasks',
  note: '/notes',
  urlShortener: '/url-shorteners',
  website: '/websites',
  chat: '/chats',
  contact: '/contacts',
  location: '/locations'
};

export const getAppRootURL = (app: any, url: any, signedIn: ISignedIn): string => {
  const features: any = signedIn.user.is;

  if (!features[app]) {
    const value = itemToURLMap[app.replace('app_', '')];

    if (is('string', value)) return value;

    for (const item of Object.keys(value)) {
      if (features[item]) return value[item];
    }
  }

  return url;
};

export const languages = {
  speech: [
    ['Afrikaans', ['af-ZA']],
    ['Bahasa Indonesia', ['id-ID']],
    ['Bahasa Melayu', ['ms-MY']],
    ['Català', ['ca-ES']],
    ['Čeština', ['cs-CZ']],
    ['Deutsch', ['de-DE']],
    ['English', ['en-AU', 'Australia'],
      ['en-CA', 'Canada'],
      ['en-IN', 'India'],
      ['en-NZ', 'New Zealand'],
      ['en-ZA', 'South Africa'],
      ['en-GB', 'United Kingdom'],
      ['en-US', 'United States']],
    ['Español', ['es-AR', 'Argentina'],
      ['es-BO', 'Bolivia'],
      ['es-CL', 'Chile'],
      ['es-CO', 'Colombia'],
      ['es-CR', 'Costa Rica'],
      ['es-EC', 'Ecuador'],
      ['es-SV', 'El Salvador'],
      ['es-ES', 'España'],
      ['es-US', 'Estados Unidos'],
      ['es-GT', 'Guatemala'],
      ['es-HN', 'Honduras'],
      ['es-MX', 'México'],
      ['es-NI', 'Nicaragua'],
      ['es-PA', 'Panamá'],
      ['es-PY', 'Paraguay'],
      ['es-PE', 'Perú'],
      ['es-PR', 'Puerto Rico'],
      ['es-DO', 'República Dominicana'],
      ['es-UY', 'Uruguay'],
      ['es-VE', 'Venezuela']],
    ['Euskara', ['eu-ES']],
    ['Français', ['fr-FR']],
    ['Galego', ['gl-ES']],
    ['Hrvatski', ['hr_HR']],
    ['IsiZulu', ['zu-ZA']],
    ['Íslenska', ['is-IS']],
    ['Italiano', ['it-IT', 'Italia'],
      ['it-CH', 'Svizzera']],
    ['Magyar', ['hu-HU']],
    ['Nederlands', ['nl-NL']],
    ['Norsk bokmål', ['nb-NO']],
    ['Polski', ['pl-PL']],
    ['Português', ['pt-BR', 'Brasil'],
      ['pt-PT', 'Portugal']],
    ['Română', ['ro-RO']],
    ['Slovenčina', ['sk-SK']],
    ['Suomi', ['fi-FI']],
    ['Svenska', ['sv-SE']],
    ['Türkçe', ['tr-TR']],
    ['български', ['bg-BG']],
    ['Pусский', ['ru-RU']],
    ['Српски', ['sr-RS']],
    ['한국어', ['ko-KR']],
    ['中文', ['cmn-Hans-CN', '普通话 (中国大陆)'],
      ['cmn-Hans-HK', '普通话 (香港)'],
      ['cmn-Hant-TW', '中文 (台灣)'],
      ['yue-Hant-HK', '粵語 (香港)']],
    ['日本語', ['ja-JP']],
    ['Lingua latīna', ['la']]
  ]
};

export const isHex = (value: string) => {
  return value && Boolean(value.match(/^0x[0-9a-f]+$/i)) && Number.isInteger(+value);
};

export const priorityToColor = (value: any) => {
  if (value === 'low') return colors.lightblue[500];

  if (value === 'medium') return colors.orange[500];

  if (value === 'important') return colors.red[500];

  return colors.gray[500];
};

export const formatNumber = (value = 0) => {
  const hasDecimal = String(value).split('.');

  return [numberWithCommas(hasDecimal[0]), hasDecimal[1]].filter(Boolean).join(',');
};
