import React from 'react';
import * as Sentry from '@sentry/react';

import { getID } from '@onesy/utils';
import { Line, Reset, useMediaQuery } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';

import { MainService, StorageService } from 'services';
import { ErrorBoundary } from 'ui';
import config from 'config';
import Routes from './Routes';

const useStyle = style(theme => ({
  '@p': {
    '*': {
      fontOpticalSizing: 'auto',

      '&::selection': {
        color: theme.methods.palette.color.value('primary', 10),
        backgroundColor: theme.methods.palette.color.value('primary', 80)
      },

      '&[contenteditable]:empty:before': {
        color: 'inherit',
        content: 'attr(data-placeholder)',
        display: 'block',
        fontFamily: 'inherit',
        fontStyle: 'inherit',
        fontWeight: 'inherit',
        opacity: 0.5,
        fontSize: '1rem'
      }
    },

    body: {
      fontFamily: theme.typography.font_family.secondary,
      color: theme.methods.palette.color.value('primary', 10),
      backgroundColor: theme.palette.light ? theme.palette.color.primary[98] : theme.palette.color.primary[10],
      height: '100vh',
      overflow: 'hidden',

      '& svg[data-logo-onesy]': {
        color: theme.palette.color.primary[theme.palette.light ? 14 : 95]
      },

      '& .onesy-themes-App-test': {
        '& .onesy-Button-root, & .onesy-Icon-root': {
          pointerEvents: 'none'
        }
      },

      '& .onesy-overflow-y': {
        flex: '1 1 auto',
        height: 0,
        overflow: 'hidden auto'
      },

      '& .onesy-overflow-x': {
        paddingBlock: 6,
        overflow: 'auto hidden'
      },

      '& .onesy-Label-helper-text, & .onesy-TextField-helper-text': {
        ...theme.typography.values.b2
      },

      '& .onesy-Snackbar-root': {
        padding: '10px 10px 10px 32px',
        borderRadius: 0,

        '& .onesy-Snackbar-primary': {
          '& > .onesy-Type-root': {
            fontSize: '1rem',
            fontWeight: 300
          }
        }
      },

      '& .onesy-DateRangePicker-root': {
        '& .onesy-TextField-input, & .onesy-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .onesy-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .onesy-TextField-root': {
        '& .onesy-TextField-input, & .onesy-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .onesy-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .onesy-Select-root': {
        '& .onesy-Select-input, & .onesy-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .onesy-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        },

        '& .onesy-ListItem-text-primary': {
          whiteSpace: 'nowrap'
        }
      },

      '& .onesy-DatePicker-root:not(.onesy-full-width), & .onesy-TextField-wrapper:not(.onesy-full-width), & .onesy-TextField-root:not(.onesy-full-width), & .onesy-Select-wrapper:not(.onesy-full-width), & .onesy-Select-root:not(.onesy-full-width), & .onesy-TextField-root:not(.onesy-full-width), & .onesy-AutoCompleteGooglePlaces-root:not(.onesy-full-width), & .onesy-AutoComplete-wrapper:has(.onesy-AutoCompleteGooglePlaces-root):not(.onesy-full-width), & .onesy-AutoCompleteCurrency-root:not(.onesy-full-width), & .onesy-AutoComplete-wrapper:has(.onesy-AutoCompleteCurrency-root):not(.onesy-full-width)': {
        width: theme.input,
        height: 50,
        flex: '0 0 auto'
      },

      '& .onesy-AutoComplete-wrapper.onesy-full-width': {
        '& .onesy-AutoComplete-root': {
          width: '100%'
        }
      },

      '& .onesy-Type-root': {
        '&.onesy-Type-version-b1, &.onesy-Type-version-b2, &.onesy-Type-version-b3': {
          fontWeight: 200
        }
      },

      '& .onesy-Modal-surface': {
        '&.onesy-Surface-root': {
          borderRadius: 0,
          background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
        }
      },

      '& .onesy-Modal-background:not(.onesy-Modal-background-invisible)': {
        background: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('default', 10), theme.palette.light ? 20 : 40)
      },

      '& .onesy-Tabs-root.onesy-Surface-root': {
        background: 'transparent',
        minHeight: 'unset'
      },

      '& .onesy-Page-root': {
        maxWidth: 'unset'
      },

      '& .onesy-mention': {
        ...theme.typography.values.l2,

        fontSize: 'inherit',
        fontWeight: 400,
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.methods.palette.color.value('primary', 30)
      },

      '& .onesy-ModalHeader-root': {
        paddingBottom: 0
      },

      '& .onesy-Tooltip-name-root': {
        '& > .onesy-DateTimePicker-main, & > .onesy-DatePicker-main, & > .onesy-TimePicker-main, & > .onesy-Calendar-root': {
          boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)'
        },

        '& > .onesy-List-root': {
          // maxWidth: 'unset',
          // width: '100%'
        }
      },

      '& .onesy-SmartTextField-root[data-placeholder="Description"]': {
        margin: '0 0 20px',
        overflow: 'hidden auto',

        ...theme.typography.values.b1
      }
    },

    a: {
      color: 'var(--onesy-palette-color-primary-30)'
    },

    '.onesy-logo-onesy': {
      color: (theme.palette.color as any).onesy.main,
      fill: (theme.palette.color as any).onesy.main
    },

    '.onesy-logo-personal-trainer': {
      color: (theme.palette.color as any)['personal-trainer'].main,
      fill: (theme.palette.color as any)['personal-trainer'].main
    },

    '.onesy-logo-website': {
      color: (theme.palette.color as any).website.main,
      fill: (theme.palette.color as any).website.main
    },

    '.onesy-logo-task': {
      color: (theme.palette.color as any).task.main,
      fill: (theme.palette.color as any).task.main
    },

    '.onesy-logo-note': {
      color: (theme.palette.color as any).note.main,
      fill: (theme.palette.color as any).note.main
    },

    '.onesy-logo-url-shortener': {
      color: (theme.palette.color as any)['url-shortener'].main,
      fill: (theme.palette.color as any)['url-shortener'].main
    },

    '.onesy-logo-chat': {
      color: (theme.palette.color as any).chat.main,
      fill: (theme.palette.color as any).chat.main
    },

    '.onesy-logo-contact': {
      color: (theme.palette.color as any).contact.main,
      fill: (theme.palette.color as any).contact.main
    }
  },

  '@media only screen and (max-width: 479px)': {
    '.onesy-Select-wrapper, .onesy-Select-root': {
      width: '100%'
    }
  },

  root: {
    height: '100vh',
    position: 'relative',
    zIndex: 14
  }
}), { name: 'Root' });

const Main = () => {
  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const light = useMediaQuery('(prefers-color-scheme: light)');

  const update = async (version = 'light', value: any = true) => {
    switch (version) {
      case 'light':
        theme.updateWithRerender({
          palette: {
            light: value
          }
        });

        StorageService.add('light', value);

        theme.inited = true;

        break;

      default:
        break;
    }
  };

  const init = React.useCallback(async () => {
    // device ID
    let deviceID = StorageService.get('device-id');

    if (!deviceID) {
      deviceID = getID();

      StorageService.add('device-id', deviceID);
    }

    MainService.init();

    if (config.value.production) {
      Sentry.init({
        dsn: config.value.services.sentry.dsn,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  // theme
  React.useEffect(() => {
    if (theme.palette.light !== light) update('light', light);
  }, [light, theme]);

  return <>
    <Reset />

    <ErrorBoundary>
      <Line
        gap={0}

        direction='column'

        align='unset'

        justify='unset'

        fullWidth

        flex

        className={classNames([
          'onesy-App',

          classes.root
        ])}
      >
        <Routes />
      </Line>
    </ErrorBoundary>
  </>;
};

export default Main;
